/* ==================================================
Active Nav:

Adds the class of 'selected' to the anchor (if any)
that matches the current path.
===================================================*/

const path = location.pathname.substring(1);
const parentPath = path.split('/')[0];

if (parentPath) {
  $(`nav a[href$="${parentPath}/"]`).addClass('selected');
  $(`nav a[href$="${parentPath}/"]`).parent().addClass('selected');
}


/* ==================================================
Dropdown List:

Toggles animated display of the next element with
the class of 'list' relative to the click trigger.
Recommend using ul structure. Default animation is
hinge in and out from left.
===================================================*/

$('.dropdown-list').on('click', '.trigger', function (e) {
  e.preventDefault();
  $(this).toggleClass('open');
  const $toggleNext = new Foundation.Toggler($(this).next('.list'), {
    animate: 'animate-in animate-out',
  });
  const trigger = $(this).next('.list');
  $(trigger).foundation('toggle');
});


/* ==================================================
Toggle element:

Toggles animated display of element with an id that
matches the 'data-toggle-trigger' of the clicked
element. The default animation is slide in and
slide out.
===================================================*/

$('a[data-toggle-trigger]').click(function (e) {
  e.preventDefault();
  const triggerId = $(this).data('toggle-trigger');
  const toggleItem = $(`#${triggerId}`);

  $(this).toggleClass('open');

  const $toggleElement = new Foundation.Toggler(toggleItem, {
    animate: 'animate-in animate-out',
  });

  toggleItem.foundation('toggle');
});


/* ==================================================
Magnific:

Magnific is used for lightbox style popups.

.image-link is for displaying larger versions of
images from a a link.

.video-link is for displaying videos from a link.
Common providers like Youtube and Vimeo will
stop playing when window is closed.
===================================================*/

$('.image-link').magnificPopup({ type: 'image' });
$('.video-link').magnificPopup({ type: 'iframe' });

$(function(){
  $('.gallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    tLoading: 'Loading image #%curr%...',
    mainClass: 'mfp-img-mobile',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0,1] // Will preload 0 - before current, and 1 after the current image
    },
    image: {
      tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
    }
  });
});


/* ==================================================
Mobile nav toggle:

Toggles the mobile nav with Foundation toggler and
animations.
===================================================*/
(function() {
  $('.mobile-menu-toggle .nav-label').click(function (e) {
    e.preventDefault();
    var $pageHeader = $('.page-header');
    var $toggleItem = $('.page-header .mobile-nav');
    $(this).toggleClass('open');

    const $toggleElement = new Foundation.Toggler($toggleItem, {
      animate: 'nav-slide-in nav-slide-out',
    });
    $pageHeader.toggleClass('open');
    $toggleItem.toggleClass('open');
    $toggleItem.foundation('toggle');
  });
})();



//Utilities
$(function(){
  $('html').removeClass('no-js');
});